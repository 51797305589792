import React from 'react'


function AsistenteIA() {
  return (
    <>
      <iframe
        src="https://app.droxy.ai/guest-agent/6707314469f34fa6ac58bd08"
        width="100%"
        height="100%"
        frameborder="0">
      </iframe>
    </>
  )
}

export default AsistenteIA