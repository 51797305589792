import React, { useContext, useEffect, useState, useRef } from 'react'
import { ProgressBar, Modal } from 'react-bootstrap';
import { Oval } from 'react-loader-spinner'
import illustrationPlanes from "../assets/img/planes.png"
import IllustrationAccess from "../assets/img/access.png"
import "./Tareas.scss"
import ModalEditProyecto from './Modales/ModalEditProyecto';
import ModalPlanes from './Modales/ModalPlanes';
import Subtareas from './Subtareas';
import { newContext } from '../pages/PlanesAccion/Proyectos'
import { CSSTransition } from 'react-transition-group';
import ModalVer from './Modales/ModalVer';
// import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd"

import { 
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from "chart.js";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
)

export const tareasContext = React.createContext()

function Tareas() {
  
  const { 
    subtareas, 
    setSubtareas, 
    loadingTar, 
    setLoadingTar, 
    setErrorTar, 
    errorTar, 
    proyectos, 
    setProyectos, 
    fetchProyectos, 
    fetchTareasById, 
    tareasByProyecto, 
    setTareasByProyecto, 
    idProyecto, 
    setIdProyecto, 
    yearSelec, 
    titleProyecto, 
    // descripcionCiclo, 
    expandedRow, 
    setExpandedRow,
    // tareasRealporProyecto,
    // tareasNorealporProyecto,
    setTareasRealporProyecto,
    setTareasNorealporProyecto,
    // fetchMetrica,
    handleCloseProyectos,
    proyectosClose
  } = useContext(newContext)
  
  const [modalDeleteProyecto, setModalDeleteProyecto] = useState(false)
  const [modalEditProyecto, setModalEditProyecto] = useState(false)
  const [modalTarea, setModalTarea] = useState(false)
  const [proyectoSelec, setProyectoSelec] = useState(null)
  const [tareaObj, setTareaObj] = useState(null)
  const [idTask, setIdTask] = useState(null)
  const [modalDeleteTarea, setModalDeleteTarea] = useState(false)
  const [errorDel, setErrorDel] = useState(null)

  const [loadingSub, setLoadingSub] = useState(true)
  const [errorSub, setErrorSub] = useState(null)

  const [modalVer, setModalVer] = useState(false)

  const [idSubtask, setIdSubtask] = useState(null)

  const [modalFinalizar, setModalFinalizar] = useState(false)
  const [errorFin, setErrorFin] = useState(null)

  // const [tareaFinal, setTareaFinal] = useState(null)

  useEffect(() => {
  },[])

  // const renderTooltip = (props) => (
  //   <Tooltip id="button-tooltip" {...props}>
  //     {descripcionProyecto}
  //   </Tooltip>
  // );

  const handleEditProyecto = () => {
    const pro = proyectos.find(e => e.id_proyecto === idProyecto)
    setProyectoSelec(JSON.stringify(pro))
    setModalEditProyecto(true)
  }

  const handleModalDeleteProyecto = () => {
    setModalDeleteProyecto(true)
  }

  const handleDeleteProyecto = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/planAccion/deleteProyect`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
          idProyecto: parseInt(idProyecto)
        })
      })
      const data = await res.json()
      if(data.error !== 0){
        console.log(data.errorDetalle)
      } else {
        setTareasByProyecto(null)
        setIdProyecto(null)
        fetchProyectos().then(res => setProyectos(res.objeto))
        setModalDeleteProyecto(false)
      }
    } catch (error) {
      console.log(error)
    } 
  }

  const handleNewTarea = (e) => {
    e.preventDefault()
    const pro = proyectos.find(e => e.id_proyecto === idProyecto)
    setProyectoSelec(JSON.stringify(pro))
    setModalTarea(true)
  }

  const handleEditTarea = (id) => {
    const obj = tareasByProyecto.find((e) => e.id_tarea === id)
    setTareaObj(JSON.stringify(obj))
    const pro = proyectos.find(e => e.id_proyecto === idProyecto)
    setProyectoSelec(JSON.stringify(pro))
    setModalTarea(true)
  }

  const handleModalDelete = (i) => {
    setIdTask(i)
    setModalDeleteTarea(true)
  }

  const handleDeleteTarea = async () => {
    const obj = {
      idTarea: parseInt(idTask)
    }
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/planAccion/deleteTask`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(obj)
      })
      const data = await res.json()
      if(data.error !== 0){
        setErrorDel(data.errorDetalle)
      } else {
        setModalDeleteTarea(false)
        // actualiza tareas
        setLoadingTar(true)
        fetchTareasById(idProyecto)
        .then(res => {
            if(res.error !== 0){
                setLoadingTar(false)
                setErrorTar(res.errorDetalle)
            } else {
                setLoadingTar(false)
                setTareasByProyecto(res.objeto)
            }
        })
        // fin de actualiza tareas
      }
    } catch (error) {
      setErrorDel(error)
    } 
  }

  const fetchSubtareasById = async (id) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/planAccion/viewSubTask`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
          idTarea: id
        })
      })
      const data = await res.json()
      return data
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubtareasById = async (id) => {
    setExpandedRow(expandedRow === id ? null : id);
    // setLoadingSub(true)
    fetchSubtareasById(id)
    .then(res => {
      if(res.error !== 0){
        setLoadingSub(false)
        setErrorSub(res.errorDetalle)
      } else {
        setLoadingSub(false)
        setSubtareas(res.objeto)
      }
    })
    setIdTask(id)
    // const obj = tareasByProyecto.find((e) => e.id_tarea === id)
    // setTareaFinal(JSON.stringify(obj))
  }

  const handleShowInfo = (id) => {
    const obj = tareasByProyecto.find((e) => e.id_tarea === id)
    setTareaObj(JSON.stringify(obj))
    setModalVer(true)
  }

  const handleModalFinalizar = (id) => {
    setIdTask(id)
    setModalFinalizar(true)
  }

  const handleFinalizarTarea = async () => {
    const obj = {
      idTarea: parseInt(idTask)
    }
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/planAccion/finalizedTask`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(obj)
      })
      const data = await res.json()
      if(data.error !== 0){
        setErrorFin(data.errorDetalle)
      } else {
        setModalFinalizar(false)
        // AGREGAR FETCH METRICAS
        // actualiza tareas
        setLoadingTar(true)
        fetchTareasById(idProyecto)
        .then(res => {
            if(res.error !== 0){
                setLoadingTar(false)
                setErrorTar(res.errorDetalle)
            } else {
                setLoadingTar(false)
                setTareasByProyecto(res.objeto)
            }
        })
        // fin de actualiza tareas
        // actualiza subtareas
        setLoadingSub(true)
        fetchSubtareasById(idTask)
        .then(res => {
            if(res.error !== 0){
                setLoadingSub(false)
                setErrorSub(res.errorDetalle)
            } else {
                setLoadingSub(false)
                setSubtareas(res.objeto)
            }
        })
      }
    } catch (error) {
      setErrorFin(error)
    }
  }

  const nodeRef = useRef()

  // const handleDragDrop = async (results) => {
  //   const {source, destination, type, draggableId } = results;
  //   if(!destination) return;
  //   if(
  //     source.droppableId === destination.droppableId && 
  //     source.index === destination.index
  //   ) 
  //     return;
  //   if(type === 'group'){
  //     const reorderedTareas = [...tareasByProyecto];
  //     const sourceIndex = source.index;
  //     const destinationIndex = destination.index;
  //     const [removedTarea] = reorderedTareas.splice(sourceIndex, 1)
  //     reorderedTareas.splice(destinationIndex, 0, removedTarea)
  //     //
  //     const inicioMod = parseInt(sourceIndex)+1
  //     const finalMod = parseInt(destinationIndex)+1
  //     // Fetch PUT MOVE TAREA
  //     const obj = {
  //       inicial: inicioMod,
  //       final: finalMod,
  //       idTarea: parseInt(draggableId),
  //       idProyecto: idProyecto
  //     }
  //     try {
  //       const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/planAccion/moveTask`, {
  //         method: "PUT",
  //         headers: {
  //             "Content-Type": "application/json"
  //         },
  //         body: JSON.stringify(obj)
  //       })
  //       const data = await res.json()
  //       if(data.error !== 0){
  //         console.log(data.errorDetalle)
  //       } else {
  //         console.log(data.objeto)
  //       }
  //     } catch (error) {
  //       console.log(error)
  //     }
  //     return setTareasByProyecto(reorderedTareas)
  //   }
  // }

  return (
    <>
      {/* Falta agregar fetch metrica */}
      <tareasContext.Provider value={{setLoadingTar, setErrorTar, tareasByProyecto, setTareasByProyecto, proyectoSelec, setProyectoSelec, tareaObj, setTareaObj, subtareas, loadingSub, setLoadingSub, errorSub, setErrorSub, fetchSubtareasById, fetchTareasById, idProyecto, setSubtareas, idTask, setTareasRealporProyecto, setTareasNorealporProyecto, idSubtask, setIdSubtask}}>
        <ModalEditProyecto show={modalEditProyecto} onHide={()=>setModalEditProyecto(false)} />
        <ModalPlanes show={modalTarea} onHide={()=>setModalTarea(false)} />
        <ModalVer show={modalVer} onHide={()=>setModalVer(false)} />
      {/* Modal Eliminar Proyecto */}
      <Modal className='modal__delete__proyecto' show={modalDeleteProyecto} onHide={() => setModalDeleteProyecto(false)} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title><h3>Eliminar proyecto</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Está seguro de eliminar este proyecto?</Modal.Body>
        <Modal.Footer>
          <button className='btn btn-secondary rounded-pill' onClick={() => setModalDeleteProyecto(false)}>Cancelar</button>
          <button className='btn btn-danger rounded-pill' onClick={handleDeleteProyecto}>Borrar</button>
        </Modal.Footer>
      </Modal>
      {/* Modal Eliminar Tarea */}
      <Modal className='modal__delete' show={modalDeleteTarea} onHide={() => setModalDeleteTarea(false)} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title><h3>Eliminar tarea</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Está seguro de eliminar esta tarea?</Modal.Body>
        <Modal.Footer className='d-flex flex-column'>
          <div className='d-flex flex-row align-items-center align-self-end'>
            <button className='btn btn-secondary rounded-pill me-2' onClick={() => setModalDeleteTarea(false)}>Cancelar</button>
            <button className='btn btn-danger rounded-pill' onClick={handleDeleteTarea}>Borrar</button>
          </div>
          {errorDel && <p>{errorDel}</p>}
        </Modal.Footer>
      </Modal>
      {/* Modal finalizar tarea */}
      <Modal className='modal__delete' show={modalFinalizar} onHide={() => setModalFinalizar(false)} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title><h3>Finalizar tarea</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Está seguro que desea finalizar esta tarea?</Modal.Body>
        <Modal.Footer className='d-flex flex-column'>
          <div className='d-flex flex-row align-items-center align-self-end'>
              <button className='btn btn-secondary rounded-pill me-2' onClick={() => setModalFinalizar(false)}>Cancelar</button>
              <button className='btn btn-danger rounded-pill' onClick={handleFinalizarTarea}>Finalizar</button>
          </div>
          {errorFin && <p>{errorFin}</p>}
        </Modal.Footer>
      </Modal>
      <div className={`${proyectosClose ? "tareas__container--close" : "tareas__container"} d-flex flex-column`}>
        <button className='d-none proyectos__btn btn border-0 p-0 d-md-flex flex-row align-items-center' onClick={handleCloseProyectos}>
          {proyectosClose ? <i className="bi bi-arrow-bar-right me-2"></i>: <i className="bi bi-arrow-bar-left me-2"></i>}
          <span>{proyectosClose ? "Mostrar proyectos" : "Ocultar proyectos"}</span>
        </button>
        {loadingTar ? (
          <div className='loading__tareas d-flex flex-column align-items-center justify-content-center'>
          <Oval
              visible={true}
              height="80"
              width="80"
              color="#0d6efd"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
          />
          <p className='fw-medium'>Loading...</p>
        </div>
        ) : (
          <>
            {errorTar ? (
              <div className='tareas__error d-flex flex-column align-items-center justify-content-center'>
                <img className='mb-4' src={IllustrationAccess} alt="" />
                <h2>Mensaje de error:</h2>
                <p>{errorTar}</p>
            </div>
            ) : (
              <>
                {tareasByProyecto === null ? (
                  <div className='tareas__inicio d-flex flex-column align-items-center justify-content-center'>
                    <img className='mb-4' src={illustrationPlanes} alt="" />
                    <h2 className='fw-semibold mb-2'>¡Bienvenido!</h2>
                    <p className='text-center w-75'>Aquí encontrarás los procesos por cada año. Cada proceso cuenta con sus propias tareas y subtareas, 
                      aquellas que deberás realizar para alcanzar los objetivos establecidos.
                    </p>
                  </div>
                ): (
                  <div className='tareas d-flex flex-column'>
                    <div className='tareas__header d-flex flex-column flex-md-row justify-content-between align-items-center mb-3'>
                      <div className='w-100 d-flex flex-row justify-content-between justify-content-md-start flex-md-wrap align-items-center mb-2 mb-md-0 gap-2'>
                          <h3 className='m-0'>{yearSelec}<i className="bi bi-chevron-right mx-2"></i>
                            <span className='tareas__header__title'>{titleProyecto}</span>
                          </h3>
                          <div className='d-flex flex-column flex-md-row'>
                              <button className='btn__edit btn bg-success rounded-circle mb-2 mb-md-0 me-md-2 text-white' onClick={()=> handleEditProyecto(idProyecto)}><i className="bi bi-pencil"></i></button>
                              <button className='btn__delete btn bg-danger rounded-circle text-white' onClick={handleModalDeleteProyecto}><i className="bi bi-trash3"></i></button>
                          </div>
                      </div>
                      <button className='btn__addTarea btn btn-primary rounded-pill fw-medium' onClick={handleNewTarea}>Agregar tarea</button>
                    </div>
                    {tareasByProyecto.length === 0 ? (
                      <div className='tareas--empty__main py-4 py-md-0 d-flex flex-column align-items-center justify-content-center rounded-3'>
                        <h2 className='fw-semibold mb-1 text-center'>No tienes tareas aún.</h2>
                        <p className='mb-3 text-center'>Para comenzar, crea tu primera tarea:</p>
                        <button 
                          onClick={handleNewTarea} 
                          className='tareas--empty__main__btn px-4 btn rounded-pill shadow-sm fw-medium'>Agregar Tarea
                        </button>
                      </div>
                    ) : (
                      <div className='tareas__main d-flex flex-column'>
                        <div className='tareas__main__tabla'>
                          {/* Table custom */}
                          <div className='table__custom'>
                            {/* Cabecera de la tabla */}
                            <div className='table__custom__header'>
                              <div className='table__custom__cell table__custom__cell--title fw-bold cell__dropdown'></div>
                              <div className='table__custom__cell table__custom__cell--title fw-bold cell__buttons--task'></div>
                              {/* <div className='table__custom__cell table__custom__cell--title fw-bold cell__orden'>Orden</div> */}
                              <div className='table__custom__cell table__custom__cell--title fw-bold cell__nombre'>Tareas</div>
                              <div className='table__custom__cell table__custom__cell--title fw-bold cell__prioridad'>Prioridad</div>
                              <div className='table__custom__cell table__custom__cell--title fw-bold cell__estado'>Estado</div>
                              <div className='table__custom__cell table__custom__cell--title fw-bold cell__progreso'>Progreso</div>
                              {/* <div className='table__custom__cell table__custom__cell--title fw-bold cell__horas'>Horas totales</div> */}
                              <div className='table__custom__cell table__custom__cell--title fw-bold cell__notas'>Notas</div>
                              <div className='table__custom__cell table__custom__cell--title fw-bold cell__mail'>Responsable</div>
                              <div className='table__custom__cell table__custom__cell--title fw-bold cell__date'>Fecha inicial</div>
                              <div className='table__custom__cell table__custom__cell--title fw-bold cell__date'>Fecha final</div>
                              <div className='table__custom__cell table__custom__cell--title fw-bold cell__area'>Área de apoyo</div>
                            </div>
                            <div className='table__custom__body'>
                              {tareasByProyecto.map((e,i) => {
                                return <React.Fragment key={i}>
                                  {/* Row de cada tarea */}
                                  <div className='table__custom__row bgblue'>
                                    <div className='table__custom__cell'>
                                      <button className='btn__ico btn p-0' onClick={()=>handleSubtareasById(e.id_tarea)}>
                                        {expandedRow === e.id_tarea ? <i className="bi bi-chevron-up"></i> : <i className="bi bi-chevron-down"></i>}
                                      </button>
                                    </div>
                                    <div className='table__custom__cell cell__buttons--task'>
                                      {Math.round(e.progreso_tarea) === 100 ? (
                                        <>
                                          <button onClick={()=> handleShowInfo(e.id_tarea)} className='btn__ico--g btn border-0 p-0'><i className="bi bi-eye"></i></button>
                                          <button className='disabled btn__ico--g btn border-0 p-0'><i className="bi bi-check-square"></i></button>
                                          <button className='disabled btn__ico--g btn border-0 p-0'><i className="bi bi-pencil"></i></button>
                                          <button className='disabled btn__ico--g btn border-0 p-0'><i className="bi bi-trash3"></i></button>
                                        </>
                                      ) : (
                                        <>
                                          <button onClick={()=> handleShowInfo(e.id_tarea)} className='btn__ico--g btn border-0 p-0'><i className="bi bi-eye"></i></button>
                                          <button onClick={()=> handleModalFinalizar(e.id_tarea)} className='btn__ico--g btn border-0 p-0'><i className="bi bi-square"></i></button>
                                          <button onClick={()=> handleEditTarea(e.id_tarea)} className='btn__ico--g btn border-0 p-0 btn__edit--icon'><i className="bi bi-pencil"></i></button>
                                          <button onClick={()=> handleModalDelete(e.id_tarea)} className='btn__ico--g btn border-0 p-0 btn__delete--icon'><i className="bi bi-trash3"></i></button>
                                        </>
                                      )}
                                      
                                    </div>
                                    {/* <div className='table__custom__cell cell__orden'>{i+1}</div> */}
                                    {/* <div className='table__custom__cell cell__orden'>{e.numero_de_orden}</div> */}
                                    <div className='table__custom__cell cell__nombre'>{e.nombre}</div>
                                    <div className='table__custom__cell cell__prioridad'>
                                      {e.prioridad === 1 && <span className='table__tbody__prioridad--baja rounded-pill text-white badge'>baja</span>}
                                      {e.prioridad === 2 && <span className='table__tbody__prioridad--media rounded-pill text-white badge'>media</span>}
                                      {e.prioridad === 3 && <span className='table__tbody__prioridad--alta rounded-pill text-white badge'>alta</span>}
                                    </div>
                                    <div className='table__custom__cell cell__estado'>
                                      {e.estado === 1 && <span className='table__tbody__estado--pendiente rounded-pill text-white badge'>Pendiente</span>}
                                      {e.estado === 2 && <span className='table__tbody__estado--proceso rounded-pill text-white badge'>En proceso</span>}
                                      {e.estado === 3 && <span className='table__tbody__estado--completada rounded-pill text-white badge'>Completada</span>}
                                      {e.estado === 4 && <span className='table__tbody__estado--espera rounded-pill text-white badge'>En espera</span>}
                                      {e.estado === 5 && <span className='table__tbody__estado--cancelada rounded-pill text-white badge'>Cancelada</span>}
                                      {e.estado === 6 && <span className='table__tbody__estado--bloqueada rounded-pill text-white badge'>Bloqueada</span>}
                                    </div>
                                    <div className='table__custom__cell cell__progreso'>
                                      <ProgressBar className='table__tbody__progreso__bar' now={Math.round(e.progreso_tarea)} label={`${Math.round(e.progreso_tarea)}%`} max={100}/>
                                    </div>
                                    {/* <div className='table__custom__cell cell__horas'>{e.horas_tarea}</div> */}
                                    <div className="table__custom__cell cell__notas">{e.notas}</div>
                                    <div className="table__custom__cell cell__mail">{e.nombreUser}</div> 
                                    <div className="table__custom__cell cell__date">{e.fecha_inicio.replace(/-/g, '/').split("/").reverse().join("/")}</div>
                                    <div className="table__custom__cell cell__date">
                                      {Math.round(e.progreso_tarea) === 100 ? `${e.fecha_final.replace(/-/g, '/').split("/").reverse().join("/")}` : ""}
                                    </div>
                                    <div className="table__custom__cell cell__area">
                                      {e.areaDeApoyo === null ? "" : e.areaDeApoyo}
                                    </div>
                                  </div>
                                  <CSSTransition
                                      in={expandedRow === e.id_tarea}
                                      timeout={300}
                                      classNames="details"
                                      unmountOnExit
                                      nodeRef={nodeRef}
                                  >
                                    <div ref={nodeRef}>
                                      <Subtareas />
                                    </div>
                                  </CSSTransition>
                                </React.Fragment>
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
      </tareasContext.Provider>
    </>
    
  )
}

export default Tareas