import React, { useState } from 'react'
import { Link, useNavigate } from "react-router-dom"
import Logo from "../../assets/img/logo.png"
import Avatar from "../../assets/img/avatar-3.jpg"
import "./Navbar.scss"
import Dropdown from 'react-bootstrap/Dropdown';

import { jwtDecode } from "jwt-decode"

function Navbar({sidebarOpen, setSidebarOpen}) {
  const token = localStorage.getItem("token")
  const obj = jwtDecode(token)
  const user = obj.apirest.objeto

  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate()

  const handleToggle = (isOpen) => {
    setIsOpen(isOpen);
  };

  const handleLogOut = () => {
    localStorage.removeItem("token");
    navigate("/")
  }

  return (
    <div className='navbar'>
        <div className="navbar__content d-none d-md-flex align-items-center justify-content-between w-100 p-0">
          <Link to="/home" className={`ms-5 ${sidebarOpen ? "d-block" : "invisible"}`}>
            <img src={Logo} alt="Logo" />
          </Link>
          {/* Menu */}
          <Dropdown onToggle={handleToggle} className="dropdowm__info">
            <Dropdown.Toggle 
              variant="white" 
              id="dropdown-custom-components" 
              className="d-flex flex-row align-items-center border-0"
            >
              <div className="d-flex flex-row align-items-center">
                <div className='me-2'>
                  <img className='rounded-circle' src={Avatar} alt="Avatar" />
                </div>
                <p className='fw-medium m-0 me-2'>{user.nombre}</p>
              </div>
              <i className={isOpen ? 'bi bi-chevron-up' : 'bi bi-chevron-down'}></i>
            </Dropdown.Toggle>

            <Dropdown.Menu className="w-100">
              <Dropdown.Item as="button">
                <Link to="/perfil" className="text-decoration-none d-flex">Perfil</Link>
              </Dropdown.Item>
              <Dropdown.Item as="button" onClick={handleLogOut}>Cerrar sesión</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
    </div>
  )
}

export default Navbar