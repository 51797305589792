import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Login from '../pages/Login/Login'
import Home from '../pages/Home/Home'
import Okr from '../pages/Okr/Okr'
import AsistenteIA from '../pages/AsistenteIA/AsistenteIA'
import DatIN from '../pages/DatIN/DatIN'

import ProtectedRoutes from '../Services/ProtectedRoutes'
import Layout from '../components/Layout/Layout'
import PowerBI from '../components/PowerBI'
import NotFound from '../pages/NotFound/NotFound'
import ResetPassword from '../pages/ResetPassword/ResetPassword'
import HomePlanesAccion from '../pages/PlanesAccion/HomePlanesAccion'
import Year from "../pages/PlanesAccion/Year"
import Proyectos from '../pages/PlanesAccion/Proyectos'
import Perfil from '../pages/Perfil/Perfil'

function MyRoutes() {
  return (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Login />}/>
            <Route path="reset-password" element={<ResetPassword />} />
            {/* protected routes */}
            <Route element={<ProtectedRoutes />}>
              <Route element={<Layout />}>
                  <Route path="/home" element={<Home />}/>
                  <Route path="/perfil" element={<Perfil />} />
                  <Route path="planes-de-accion">
                    <Route index element={<HomePlanesAccion />} />
                    <Route path=':year'>
                      <Route index element={<Year />} />
                      <Route path='proyectos' element={<Proyectos />}></Route>
                    </Route>
                  </Route>
                  <Route path="/okr" element={<Okr />}/>
                  <Route path="/asistente-ia" element={<AsistenteIA />}/>
                  <Route path="/dat-in" element={<DatIN />}/>
                  <Route path="bi/:area" element={<PowerBI />}></Route>
                  <Route path="*" element={<NotFound />} />
              </Route>
            </Route>
        </Routes>
    </BrowserRouter>
  )
}

export default MyRoutes