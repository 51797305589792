import React from 'react'
import ComingSoon from "../../components/ComingSoon"

function Okr() {
  return (
    <>
      <ComingSoon />
    </>
  )
}

export default Okr