import React, { useState, useEffect, useRef } from 'react'
import { useParams, useNavigate } from "react-router-dom"
import './Year.scss'
import { jwtDecode } from "jwt-decode"
import { Oval } from 'react-loader-spinner'
import { 
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    TimeScale
} from "chart.js";
import { Doughnut, Bar, getElementAtEvent } from 'react-chartjs-2'
import 'chartjs-adapter-date-fns';
import {es} from 'date-fns/locale';
import IllustrationAccess from "../../assets/img/access.png"
import Indicadores from "../../assets/img/indicadores-2.png"
import ModalProyecto from '../../components/Modales/ModalProyecto';

export const yearContext = React.createContext()

// Marca el día actual
const todayLine = {
    id: 'todayLine',
    afterDatasetsDraw(chart, args, pluginOptions) {
        // const { ctx, data, chartArea: {top, bottom, left, right}, scales: { x, y } } = chart;
        const { ctx, chartArea: {top, bottom}, scales: { x } } = chart;
        ctx.save();
        ctx.beginPath();
        ctx.lineWidth = 2;
        ctx.strokeStyle = '#6c757d';
        ctx.moveTo(x.getPixelForValue(new Date()), top);
        ctx.lineTo(x.getPixelForValue(new Date()), bottom);
        ctx.stroke();
    }
}

ChartJS.register(
    ArcElement, 
    Tooltip, 
    Legend, 
    BarElement, 
    CategoryScale, 
    LinearScale, 
    TimeScale,
)

function Year() {
    const { year } = useParams()
    const navigate = useNavigate();

    // state
    const [proyectos, setProyectos] = useState([])
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)

    // const [totalTareas, setTotalTareas] = useState(null)
    // const [tareasRealiz, setTareasRealiz] = useState(null)
    // const [tareasNorealiz, setTareasNorealiz] = useState(null)

    const [modalProyecto, setModalProyecto] = useState(false)

    const [isFullscreen, setIsFullscreen] = useState(false)
    const containerRef = useRef(null)

    const auth = localStorage.getItem("token")
    const jwtParse = jwtDecode(auth)
    const USER = jwtParse.apirest.objeto

    useEffect(()=>{
        const firstFetch = () => {
            fetchProyectos()
            .then(res => {
                if(res.error !== 0){
                    setLoading(false)
                    setError(res.errorDetalle)
                } else {
                    setLoading(false)
                    setProyectos(res.objeto)
                }
            })

        }

        firstFetch()
    },[])

    // Fullscreen
    const enterFullscreen = () => {
        if (containerRef.current) {
            if (containerRef.current.requestFullscreen) {
                containerRef.current.requestFullscreen();
            } else if (containerRef.current.mozRequestFullScreen) { // Firefox
                containerRef.current.mozRequestFullScreen();
            } else if (containerRef.current.webkitRequestFullscreen) { // Chrome, Safari and Opera
                containerRef.current.webkitRequestFullscreen();
            } else if (containerRef.current.msRequestFullscreen) { // IE/Edge
                containerRef.current.msRequestFullscreen();
            }
            setIsFullscreen(true);
        }
    };

    const exitFullscreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { // Firefox
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { // IE/Edge
            document.msExitFullscreen();
        }
        setIsFullscreen(false);
    };

    const handleFullscreenToggle = () => {
        if (isFullscreen) {
            exitFullscreen();
        } else {
            enterFullscreen();
        }
    };
    // Fin fullscreen

    // Actualizar el listado de proyectos
    const fetchProyectos = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/planAccion/viewProyect`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                user: USER
                })
            });
            const data = await res.json()
            return data
        } catch (error) {
            setError(error)
        }
    }

    const arrGantt = []
    proyectos.map((e) => {
        return arrGantt.push(
            {
                y: e.nombre,
                x: [e.fecha_inicio, e.fecha_final],
                idProyecto: e.id_proyecto,
                // percentage: '50%'
            }
        )
    })

    const arrGanttReal = []
    proyectos.forEach((e) => {
        if(e.fecha_final_tareas !== null){
            arrGanttReal.push(
                {
                    y: e.nombre,
                    x: [e.fecha_inicio_tareas, e.fecha_final_tareas]
                }
            )
        } else {
            arrGanttReal.push({})
        }
    })

    // Bar Chart
    const dataBar = {
        datasets : [
          {
            label: 'Fechas establecidas',
            data: arrGantt,
            backgroundColor: '#0d6efd',
            borderWidth: 0,
            borderSkipped: false,
            borderRadius: 12,
            barThickness: 18
          },
          {
            label: 'Fechas reales',
            data: arrGanttReal,
            backgroundColor: '#6ea8fe',
            borderWidth: 0,
            borderSkipped: false,
            borderRadius: 12,
            barThickness: 18
          }
        ]
    }

    const numBars = dataBar.datasets[0].data.length;
    const containerHeight = 40 * numBars;

    const optionsBar = {
        plugins: {
            legend: {
                display: true,
                labels: {
                    font: {
                        family: 'sans-serif'
                    }
                }
            },
            tooltip: {
                callbacks : {
                    label: (ctx) => {
                        const startDate = new Date(ctx.raw.x[0]).toLocaleDateString('es-ES', {timeZone: 'UTC', day: '2-digit', month: 'short'})
                        const endDate = new Date(ctx.raw.x[1]).toLocaleDateString('es-ES', {timeZone: 'UTC', day: '2-digit', month: 'short'})
                        return `${startDate} - ${endDate}`
                    }
                }
            }
        },
        layout: {},
        indexAxis: 'y',
        onHover: (event, chartElement) => {
            event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
        },
        scales: {
            x: {
                position: 'top',
                type: 'time',
                time: {
                    unit: 'month'
                },
                ticks: {
                    callback: (value, index, ticks) => {
                        const date = new Date(value);
                        return new Intl.DateTimeFormat('es-ES', {
                            // year: 'numeric',
                            month: 'short',
                        }).format(date)
                    },
                    font: {
                        family: 'sans-serif'
                    }
                },
                min: '2024-01-01',
                max: '2024-12-31'
            },
            y: {
                ticks: {
                    font: {
                        family: 'sans-serif'
                    }
                }
            }
        },
        adapters: {
            date: {
                locale: es
            }
        },
        // responsive: true,
        // maintainAspectRatio: false,
    }

    const chartRef = useRef()
    const onClick = (e) => {
        if(getElementAtEvent(chartRef.current, e).length > 0){
            const dataPoint = getElementAtEvent(chartRef.current, e)[0].index
            const data = {
                title: dataBar.datasets[0].data[dataPoint].y,
                year: year,
                id: dataBar.datasets[0].data[dataPoint].idProyecto
            }
            navigate(`/planes-de-accion/${year}/proyectos`, {state: data})
        }
    }

    const handleFormProyecto = (e) => {
        e.preventDefault()
        setModalProyecto(true)
    }

    return (
        <>
            <yearContext.Provider value={{proyectos, setProyectos, fetchProyectos}}>
                <ModalProyecto show={modalProyecto} onHide={()=>setModalProyecto(false)}/>
            </yearContext.Provider>
            <div className='proyectos__year section'>
                <div className='section__header d-flex flex-row align-items-end mb-4'>
                    <i className='bi bi-bar-chart-steps me-2'></i>
                    <h4 className='m-0'>Planes de acción {year}</h4>
                </div>
                {/* <button onClick={()=>navigate(-1)}>Anterior</button> */}
                {loading ? (
                    <div className='loading__year d-flex flex-column align-items-center justify-content-center'>
                        <Oval
                            visible={true}
                            height="80"
                            width="80"
                            color="#0d6efd"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                        <p className='fw-medium'>Loading...</p>
                    </div>
                ) : (
                    <>
                        {error ? (
                            <div className='d-flex flex-column align-items-center justify-content-center'>
                                <img className='mb-4' src={IllustrationAccess} alt="" />
                                <h2>Mensaje de error:</h2>
                                <p>{error}</p>
                            </div>
                        ) : (
                            <div className='proyectos__year__main'>
                                <div className='proyectos__year__main__content'>
                                    <div className='proyectos__year__main__content__introduccion rounded-3 mb-4'>
                                        <h2 className='text-white'>Bienvenido/a a los planes de acción del año {year}</h2>
                                        <p className='text-white mb-0'>Aquí encontrarás las estadísticas de los planes de acción del año {year}.</p>
                                    </div>
                                    {proyectos.length === 0 ? (
                                        <div className='proyectos__year__main__content__gantt--empty d-flex flex-column align-items-center justify-content-center rounded-3'>
                                            <h2 className="fw-semibold mb-1 text-center">No tienes proyectos aún.</h2>
                                            <p className='mb-3 text-center'>Para comenzar, crea tu primer proyecto:</p>
                                            <button onClick={handleFormProyecto} className='px-4 btn btn-primary text-white rounded-pill shadow-sm fw-medium'>Agregar proyecto</button>
                                        </div>
                                    ) : (
                                        <div ref={containerRef} className='proyectos__year__main__content__grafica'>
                                        {/* <div ref={containerRef} className='proyectos__year__main__content__grafica' style={{ height: `${containerHeight}px`}}> */}
                                            <Bar 
                                                data={dataBar}
                                                options={optionsBar}
                                                // plugins={[todayLine, viewPercentage]}
                                                plugins={[todayLine]}
                                                onClick={onClick}
                                                ref={chartRef}
                                            />
                                            <button onClick={handleFullscreenToggle} className='btn__expandir btn p-0'>
                                                {isFullscreen ? <i className="bi bi-arrows-angle-contract"></i> : <i className="bi bi-arrows-angle-expand"></i>}
                                            </button>
                                        </div>
                                    )}
                                </div>
                                {proyectos.length === 0 ? (
                                    <div className='proyectos__year__main__aside--empty d-flex flex-column rounded-3'>
                                        <div className='proyectos__year__main__aside--empty__textos mb-3'>
                                            <h2 className='text-white'>Mantente al tanto de tus proyectos</h2>
                                            <p className='text-white m-0'>Aquí encontrarás información relevante según las características de tus tareas.</p>
                                        </div>
                                        <img src={Indicadores} alt='Indicadores' />
                                    </div>
                                ) : (
                                    <div className='proyectos__year__main__aside'>
                                        {/* <div className='proyectos__year__main__aside__graficas'>
                                            <div className='doughnut__grafica d-flex flex-column shadow-sm rounded-3 border border-light-subtle'>
                                                <div className='doughnut__grafica__info d-flex flex-row align-items-center'>
                                                    <div className='doughnut__grafica__info__textos'>
                                                        <h4 className='mb-2'>Total de tareas</h4>
                                                        <p className='mb-1 fw-medium'>Tareas realizadas: <span>{tareasRealiz}</span></p>
                                                        <p className='mb-0'>Tareas no realizadas: <span>{tareasNorealiz}</span></p>
                                                    </div>
                                                    <div className='doughnut__grafica__info__chart'>
                                                        <Doughnut 
                                                            data = {data}
                                                            options={options}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                    
                )}
            </div>
        </>
    )
}

export default Year