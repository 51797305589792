import React, { useEffect } from 'react'
import { useState } from "react"
import Logo from "../../assets/img/logo.png"
import Ilustration from "../../assets/img/ilustration.png"
import "./Login.scss"
import { useNavigate, useLocation } from "react-router-dom"
import { jwtDecode } from "jwt-decode"
import { Oval } from 'react-loader-spinner'
import { Modal } from 'react-bootstrap';

function Login() {
    const location = useLocation();

    const [formData, setFormData] = useState({
        email: "",
        password: ""
    })

    const [fetchError, setFetchError] = useState(null)
    const [errors, setErrors] = useState({})

    const [loading, setLoading] = useState(false)

    const [modalPassword, setModalPassword] = useState(false)

    const navigate = useNavigate()

    useEffect(()=>{
        if(localStorage.getItem("token")) {
            navigate("/home")
        }

        // Modal luego del cambio de contraseña
        if(location.state && location.state.codigo === 0){
            setModalPassword(true)
        }
        const timer = setTimeout(() => {
            setModalPassword(false);
        }, 5000);
        return () => clearTimeout(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[location.state])

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({
            ...formData,
            [name]: value
        })
    }
    
    const validateForm = (data) => {
        const errors = {}

        if(!data.email.trim()){
            errors.email = "Escriba el mail."
        } else if (!/\S+@\S+\.\S+/.test(data.email)){
            errors.email = "El email no es válido."
        }
        if(!data.password.trim()) {
            errors.password = "Escriba su contraseña."
        }
        return errors;
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault()
        const newErrors = validateForm(formData)
        setErrors(newErrors)
        if(Object.keys(newErrors).length === 0){
            setFetchError(null)
            const reqBody = {
                mail: formData.email,
                pass: formData.password
            }
            try {
                setLoading(true)
                const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/user/login`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(reqBody)
                })
                const data = await res.json()
                const obj = jwtDecode(data)
                if(obj.apirest.status === 15){
                    setLoading(false)
                    navigate("/reset-password", {state: formData.email})
                } else if (obj.apirest.status === 0) {
                    setLoading(false)
                    localStorage.setItem('token', JSON.stringify(data))
                    navigate("/home")
                } else {
                    // Incluyen error 10 (contraseña incorrecta) y error 99 (usuario inactivo o prueba de test finalizada)
                    setLoading(false)
                    setFetchError(obj.apirest.codeError)
                }
            } catch (error) {
                setFetchError(error)
            }
        } else {
            setFetchError("Completar los campos mencionados.")
        }
    }

    const handleClose = () => setModalPassword(false);

    return (
        <>
            {modalPassword && <Modal show={modalPassword} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>¡Contraseña actualizada!</Modal.Title>
                </Modal.Header>
                <Modal.Body>Ingresa con tu nueva contraseña :)</Modal.Body>
            </Modal>
            }
            {/* Loading */}
            {loading ? (<div className='loading vh-100 vw-100 z-3 position-absolute d-flex flex-column align-items-center justify-content-center'>
                <Oval
                    visible={true}
                    height="80"
                    width="80"
                    color="#fff"
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
                <p className='text-white fw-medium'>Loading...</p>
            </div>): ("") 
            }
            <div className="login vh-100 d-flex align-items-center justify-content-center">
                <div className="login__container d-flex flex-row bg-white shadow-sm rounded">
                    <div className="login__container__form col-12 col-md-6 d-flex flex-column align-items-center justify-content-center">
                        <img className="login__container__form__img mb-4" src={Logo} alt="Logo" />
                        <form className="w-100 d-flex flex-column align-items-center" onSubmit={handleSubmit}>
                            <div className="w-100 d-flex flex-column mb-3">
                                <label htmlFor="email" className="form-label">Correo</label>
                                <input 
                                    onChange={handleChange} 
                                    type="email" 
                                    id="email" 
                                    name="email" 
                                    placeholder="ejemplo@correo.com.ar" 
                                    className="form-control"
                                    value={formData.email}
                                />
                                {errors.email && <span className='form__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.email}</span>}
                            </div>
                            <div className='w-100 d-flex flex-column mb-4'>
                                <label htmlFor="password" className='form-label'>Contraseña</label>
                                <input 
                                    onChange={handleChange} 
                                    type="password" 
                                    id='password' 
                                    name="password" 
                                    placeholder='' 
                                    className='form-control'
                                    value={formData.password}
                                />
                                {errors.password && <span className='form__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.password}</span>}
                            </div>
                            {fetchError ? <div className='mb-4'><i className="bi bi-exclamation-circle pe-2 text-danger"></i><span className='text-danger'>{fetchError}</span></div> : ""}
                            <button onClick={handleSubmit} className="btn btn-primary" type='submit'>Iniciar Sesión</button>
                        </form>
                    </div>
                    <div className='login__container__info d-none d-md-flex flex-column justify-content-center col-md-6 bg-gray-100 rounded-end'>
                        <h1 className="login__container__info__title mb-2">Muchos pueden mirar, pero pocos pueden ver.</h1>
                        <h4 className="login__container__info__subtitle mb-4">Plataforma de Gestión profesional y seguimiento con datos.</h4>
                        <img src={Ilustration} alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login