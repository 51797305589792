import React from 'react'
import MyRoutes from "./router/routes"
import './App.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';

function App() {
  return (
    <>
      <MyRoutes />
    </>
  );
}

export default App;
